import React from 'react';

import { splitLines } from '@/services/regex';

interface Props {
    readonly text: string
    readonly externalStyles?: string;
    readonly hideParagraphPadding?: boolean
}

/**
 * This component takes a string of text and removes \r and then
 * splits each new line into its own paragraph. Use externalStyles
 * to control the styles of the parent div wrapping the p tags
 */
export default function LineBreakParagraphs({
    text,
    externalStyles = '',
    hideParagraphPadding = false,
}: Props) {
    // default to h3
    const baseStyles = '';

    const className = `${baseStyles} ${externalStyles}`;

    if (!text) {
        return null;
    }

    const lines = splitLines(text);
    return (
        <div className={className}>
            {lines.map((item, i) => (
                // eslint-disable-next-line react/no-array-index-key
                <p key={i} className={!hideParagraphPadding ? 'pb-2' : undefined}>{item}</p>
            ))}
        </div>
    );
}
