import React, { useEffect, useState } from 'react';
import router from 'next/router';

import { type Brief, type UserContent } from '@/components/with_auth/State';
import routes from '@/services/routes';
import LineBreakParagraphs from '@/components/commons/page-elements/LineBreakParagraph';
import ProfileImage from '@/components/commons/ProfileImage';
import { BIO_STATES } from '@/components/onboarding/Shared';
import LinkIcon from '@/components/commons/icons/LinkIcon';
import Star from '@/components/commons/icons/Star';

import { formatSocialNames } from '@/components/new-brief/helpers';
import PreferencesPanel from './PreferencesPanel';
import EditNavigator from './EditNavigator';
import BioWorkPanel from './BioWorkPanel';

// TODO(tom) use date library?
const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
];

const labelMap = {
    linkedIn: 'LinkedIn',
    xTwitter: 'X (Twitter)',
    facebook: 'Facebook',
    instagram: 'Instagram',
    gitHub: 'Github',
    website: 'Website',
    other_1: 'Other 1',
    other_2: 'Other 2',
};

const getBirthday = (dateString) => {
    if (!dateString) {
        return 'N/A';
    }
    const birthday = new Date(dateString);

    return `${months[birthday.getUTCMonth()]} ${birthday.getUTCDate()}`;
};

interface Props {
    readonly brief: Partial<Brief>,
    readonly user: UserContent,
    readonly openModal?: (step: number, isEdit: boolean) => void,
}

function BioBrief({
    brief,
    user,
    openModal = (_step: number, _isEdit: boolean) => { },
}: Props) {
    const [briefBelongsToViewer, setBriefBelongsToViewer] = useState<boolean>(false);

    useEffect(() => {
        if (user) {
            const keys = user.briefs.map((singleBrief) => singleBrief.displayKey);
            setBriefBelongsToViewer(keys.includes(brief.displayKey));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user]);

    const getReturnRoute = () => {
        if (router.asPath === '/bio/review-your-bio/') {
            return router.asPath;
        }

        if (router.asPath !== routes.BIO && router.asPath !== '/bio/review-your-bio/') {
            return `/${brief.displayKey}`;
        }

        return null;
    };

    let links = [];
    if (brief.socialLinks) {
        links = Object.keys(brief.socialLinks).map((key) => ({
            type: key,
            label: labelMap[key],
            value: brief.socialLinks[key],
        })).filter((item) => item.value).reverse();
    }

    const briefUrl = routes.BRIEF_URL(brief.displayKey);
    return (
        <div className="bg-cirrusWhite rounded-lg shadow-lg overflow-hidden flex flex-col lg:flex-row h-full p-5 lg:px-10 lg:py-5">
            <div className="w-full lg:w-3/4 md:mr-10">
                {/* header */}
                <div className="block sm:flex items-center">
                    <div className="mr-0 sm:mr-5">
                        <ProfileImage brief={brief} height="h-[100px]" width="w-[100px]" />
                    </div>
                    <div className="flex">
                        <div>
                            <h1 className="text-2xl text-cleanSlate font-jazmin">{brief.fullName}</h1>
                            <p className="font-bold text-cleanSlate text-slg">
                                {brief.title}
                                {brief.company && (
                                    <span className="font-normal">
                                        ,
                                        {' '}
                                        {brief.company}
                                    </span>
                                )}
                            </p>
                        </div>
                        {(user && briefBelongsToViewer) && (
                            <EditNavigator
                                openModal={router.asPath === routes.BIO && openModal}
                                step={0}
                                route={!user.bioOnly ? routes.BIO : routes.BIO_PERSONALIZE}
                                returnRoute={getReturnRoute()}
                                className="cursor-pointer text-base leading-6 text-cleanSlate"
                            />
                        )}
                    </div>
                </div>
                {/* end header */}
                {/* start bio */}
                <div className="flex">
                    <LineBreakParagraphs text={brief.biography} externalStyles="text-base leading-6 text-primary mt-3" />
                    {(user && briefBelongsToViewer) && (
                        <EditNavigator
                            openModal={router.asPath === routes.BIO && openModal}
                            route={!user.bioOnly ? routes.BIO : routes.BIO_EDIT_BIO}
                            returnRoute={getReturnRoute()}
                            className="cursor-pointer text-base leading-6 text-cleanSlate"
                            step={BIO_STATES.editBio}
                        />
                    )}
                </div>
                {/* end bio */}
                {brief.displayWorkHistory && brief.workHistoryItems.length > 0 && (
                    <div>
                        <h2 className="font-bold text-cleanSlate pt-4 pb-2">Career History</h2>
                        {brief.workHistoryItems.map((entry) => (
                            <BioWorkPanel
                                key={entry.id}
                                id={entry.id}
                                title={entry.title}
                                organization={entry.organization}
                                startAt={entry.startAt}
                                endAt={entry.endAt}
                            >
                                {/* TODO(tom) this doesn't make sense to go to edit bio
                                    we need a flow for going back to edit work history
                                {(user && briefBelongsToViewer)
                                && (
                                    <EditNavigator
                                        openModal={router.asPath === routes.BIO && openModal}
                                        route={!user.bioOnly ? routes.BIO : routes.BIO_EDIT_BIO}
                                        returnRoute={getReturnRoute()}
                                    className="cursor-pointer text-base leading-6 text-primary px-2"
                                        step={BIO_STATES.editBio}
                                    />
                                )} */}
                            </BioWorkPanel>
                        ))}
                    </div>
                )}
                {/* meeting preferences */}
                {(brief.meetingPreferences || briefBelongsToViewer) && (
                    <PreferencesPanel
                        header="Meeting"
                        username={brief.fullName}
                        isUser={briefBelongsToViewer}
                        subheader="meeting"
                        preferences={brief.meetingPreferences || 'You haven\'t provided your meeting preferences. '}
                        iconUrl="/images/icon-calendar.svg"
                        step={8}
                        route={!user?.bioOnly ? routes.BIO : routes.BIO_MEETING_PREFERENCES}
                        openModal={router.asPath === routes.BIO && openModal}
                        returnRoute={getReturnRoute()}
                    />
                )}
                {/* end meeting prefrences */}
                {/* comms preferences */}
                {(brief.communicationPreferences || briefBelongsToViewer) && (
                    <PreferencesPanel
                        header="Communications"
                        username={brief.fullName}
                        isUser={briefBelongsToViewer}
                        subheader="messages"
                        preferences={brief.communicationPreferences || 'You haven\'t provided your communications preferences. '}
                        iconUrl="/images/icon-caption.svg"
                        step={7}
                        route={!user?.bioOnly ? routes.BIO : routes.BIO_COMMUNICATION_PREFERENCES}
                        openModal={router.asPath === routes.BIO && openModal}
                        returnRoute={getReturnRoute()}
                    />
                )}

            </div>
            <div className="w-full lg:w-1/4 lg:border-l lg:pl-8">
                {/* location */}
                <div className="mt-5 flex flex-col">
                    <div className="w-full flex">
                        <p className="font-bold">
                            {brief.location ? 'Location' : 'No Location Provided'}
                            {' '}
                        </p>
                        {brief.location && briefBelongsToViewer && (
                            <EditNavigator
                                openModal={router.asPath === routes.BIO && openModal}
                                returnRoute={getReturnRoute()}
                                route={!user.bioOnly ? routes.BIO : routes.BIO_SOCIAL_LINKS}
                                step={BIO_STATES.socialLinks}
                            />
                        )}
                    </div>

                    {brief.location
                        && (
                            <div className="flex  mt-1">
                                <img src="/images/icon-pin.svg" alt="link" className="pr-2 text-center" />
                                <p>{brief.location}</p>
                            </div>
                        )}

                </div>
                {/* birthday */}
                <div className="flex mt-5 flex-col">
                    <div className="w-full flex">
                        <p className="font-bold">{brief.birthday ? 'Birthday' : 'No Birthday Provided'}</p>
                        {briefBelongsToViewer
                            && (
                                <EditNavigator
                                    openModal={router.asPath === routes.BIO && openModal}
                                    step={0} // personalize is at 0 for modal
                                    route={!user.bioOnly ? routes.BIO : routes.BIO_PERSONALIZE}
                                    returnRoute={getReturnRoute()}
                                    className="cursor-pointer text-base leading-6 text-cleanSlate"
                                />
                            )}
                    </div>

                    {brief.birthday && (
                        <div className="flex mt-1">
                            <Star className="w-5 h-5 pr-2 mt-0.5 text-cleanSlate" />
                            <p>{getBirthday(brief.birthday)}</p>
                        </div>
                    )}
                </div>
                {/* sixty bio */}
                {brief.displayKey && (
                    <div>
                        <div className="flex mt-5">
                            <p className="font-bold">Sixty brief</p>
                            {briefBelongsToViewer && (
                                <EditNavigator
                                    openModal={router.asPath === routes.BIO && openModal}
                                    route={!user.bioOnly ? routes.BIO : routes.BIO_COMPLETE_SHARE}
                                    returnRoute={getReturnRoute()}
                                    className="cursor-pointer text-base leading-6 text-cleanSlate"
                                    step={11}
                                />
                            )}
                        </div>
                        <div className="flex items-start mt-1">
                            <LinkIcon className="w-5 h-5 pr-2 mt-1 text-cleanSlate" />
                            <p className="text-progressBarBlue overflow-hidden">
                                <a className="block whitespace-nowrap text-ellipsis overflow-hidden" href={briefUrl}>
                                    {brief.displayKey}
                                </a>
                            </p>
                        </div>
                    </div>
                )}
                <div className="mt-8">
                    <div className="flex">
                        <p className="font-bold">
                            Find
                            {' '}
                            {brief.fullName}
                            {' '}
                            at...
                        </p>
                        {(user && briefBelongsToViewer) && (
                            <EditNavigator
                                openModal={router.asPath === routes.BIO && openModal}
                                step={BIO_STATES.socialLinks}
                                route={!user.bioOnly ? routes.BIO : routes.BIO_SOCIAL_LINKS}
                                returnRoute={getReturnRoute()}
                                className="cursor-pointer text-base leading-6 text-cleanSlate"
                            />
                        )}
                    </div>
                    <ul className="py-3">
                        {links.map((item) => formatSocialNames(item.type, item.value))}
                    </ul>
                </div>
            </div>
        </div>
    );
}

export default BioBrief;
