import router from 'next/router';
import React from 'react';

import Pencil from '@/components/commons/icons/Pencil';

interface Props {
    readonly route: string;
    readonly returnRoute: string;
    readonly className?: string;
    readonly openModal?: (step: number, isEdit: boolean) => void,
    readonly step: number,
    readonly isEmptyPreferences?: boolean;
}

function EditNavigator({
    route,
    returnRoute,
    className = '',
    openModal = (_step: number, _isEdit: boolean) => { },
    step,
    isEmptyPreferences = false,
}: Props) {
    return (
        <button
            type="button"
            aria-label="Edit property"
            className={`${className} cursor-pointer flex items-end`}
            onClick={async () => {
                if (openModal) {
                    if (returnRoute) {
                        await router.push(route);
                    }
                    openModal(step, true);
                } else {
                    await router.push(route);
                }
            }}
        >
            {isEmptyPreferences ? <p className="text-progressBarBlue pl-1">Click here to edit.</p>
                : (
                    <Pencil className="h-4 w-4 ml-3 my-auto text-progressBarBlue" />
                )}
        </button>
    );
}

export default EditNavigator;
