import React from 'react';

import LineBreakParagraphs from '@/components/commons/page-elements/LineBreakParagraph';

import EditNavigator from './EditNavigator';

interface Props {
    readonly header: string;
    readonly username: string;
    readonly subheader: string;
    readonly preferences: string;
    readonly iconUrl: string;
    readonly step: number;
    readonly route: string;
    readonly isUser: boolean;
    readonly returnRoute?: any;

    readonly openModal: (step: number, isEdit: boolean) => void;
}

export default function PreferencesPanel({
    header,
    username,
    subheader,
    preferences,
    iconUrl,
    step,
    route,
    isUser,
    openModal,
    returnRoute = null,
}: Props) {
    return (
        <div className="bg-gray-100 rounded-lg mt-5 p-4 flex">
            <div className="pr-2 pt-1 shrink-0">
                <img src={iconUrl} alt="graphic" />
            </div>
            <div>
                <div className="mb-5">
                    <p className="font-bold text-base text-cleanSlate">
                        {header}
                        {' '}
                        Preferences
                    </p>
                    <p className="text-sm text-cleanSlate pt-[4px]">
                        {username}
                        &apos;s
                        availability and preferences for
                        {' '}
                        {subheader}
                        {' '}
                        with new and existing contacts
                    </p>
                </div>
                <div className="flex">
                    <LineBreakParagraphs
                        text={preferences}
                        externalStyles="text-base text-cleanSlate leading-6"
                        hideParagraphPadding
                    />
                    {' '}
                    {isUser
                        && (
                            <EditNavigator
                                returnRoute={returnRoute}
                                route={route}
                                openModal={openModal}
                                step={step}
                                isEmptyPreferences={preferences === `You haven't provided your ${header.toLocaleLowerCase()} preferences.`}
                            />
                        )}

                </div>
            </div>

        </div>
    );
}
