import React from 'react';

interface Props {
    readonly id: string;
    readonly title: string;
    readonly organization: string;
    readonly startAt: string;
    readonly endAt: string;
    readonly children?: any;
}

function BioWorkPanel({
    title,
    organization,
    startAt,
    endAt,
    id,
    children = undefined,
} : Props) {
    return (
        <div key={id} className="flex justify-between items-center h-[61px] my-4 bg-gray-100 rounded-lg p-4">
            <p className="text-sm font-bold px-4">{title}</p>
            <div className="flex items-center">
                <p className="text-sm font-normal px-2">{organization}</p>
            </div>
            <div className="flex items-center">
                <img src="/images/icon-calendar.svg" className="h-4 w-4" alt="graphic" />
                <p className="text-sm font-normal px-2">
                    {startAt.split('-')[0]}
                    {' - '}
                    {!endAt ? 'Current' : endAt.split('-')[0]}
                </p>
            </div>
            {children}
        </div>
    );
}

export default BioWorkPanel;
